// React imports
import React from 'react'
import { fontSizeMap, heightMap } from '~/components/inputs/InputUtils'

// Remix imports

// Third party imports

// Generated imports

// App imports
import FormError from '~/components/typography/FormError'
import Label from '~/components/typography/Label'
import P2 from '~/components/typography/P2'

export interface Props
  extends InputProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  error?: string
  iconRight?: any
  inputClassName?: string
  label?: string
  required?: boolean
  marginBottom?: 'mb-3' | 'mb-0'
  readonly?: boolean
  rounded?: boolean
  bold?: boolean
}

const InputText: React.FC<Props> = ({
  children,
  className,
  error,
  iconRight,
  inputClassName,
  label,
  required,
  marginBottom = 'mb-3',
  inputType = 'form',
  readonly,
  defaultValue,
  rounded,
  bold,
  ...other
}) => {
  const { name } = other
  const id_ = `id_${name}`
  const errorId = `id_${name}_error`

  return (
    <div
      className={
        `${marginBottom} w-full text-left` + (className ? ` ${className}` : '')
      }
    >
      {label && (
        <Label
          htmlFor={id_}
          inputType={inputType}
          className={'mb-1 ' + (bold ? 'font-bold ' : '')}
        >
          {label}
          {required ? ' *' : ''}
        </Label>
      )}
      {!readonly && (
        <div className="relative">
          <input
            aria-label={label}
            aria-required={required}
            aria-invalid={!!error}
            aria-errormessage={errorId}
            id={id_}
            name={name}
            className={
              `${heightMap[inputType]} ${fontSizeMap[inputType]} w-full border border-brand-input-border bg-transparent px-2 font-sansSerif leading-none antialiased focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-gray-bg ` +
              (error ? ' border-danger' : '') +
              (rounded ? ' rounded' : '') +
              (inputClassName ? ` ${inputClassName}` : '')
            }
            defaultValue={defaultValue}
            {...other}
          />
          {iconRight}
        </div>
      )}
      {readonly && <P2>{defaultValue || 'n/a'}</P2>}
      {name && (
        <FormError
          id={errorId}
          error={error}
          inputType={inputType}
        />
      )}
    </div>
  )
}

export default InputText
